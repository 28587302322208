body {
  margin: 0;
}

.App {
  height: 100vh;
}

html {
  overflow-x: hidden;
  scrollbar-width: none;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
